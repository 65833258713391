import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'calculator',
	  component:() =>  import('@/views/calculator/calculator'),
  	// component:() =>  import('@/test/testCalCulator'),
  },
  
  // {
  // 	path: '/calculator',
  // 	name:'calculator',
  // 	component:() =>  import('@/views/calculator/calculator'),
  // },
  {
  	path: '/setUp',
  	name:'setUp',
  	component:() =>  import('@/views/calculator/setUp'),
  },
  {
  	path: '/history',
  	name:'history',
  	component:() =>  import('@/views/calculator/history'),
  },
]

const router = new VueRouter({
  routes
})

export default router
