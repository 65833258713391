import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    historyList: [], //
    stashCalStatus:{
      stashPreExpression:'',
      stashExpression:'', // 对应current
      stashResult:'',
      stashNextCalFlag:false,
      stashIsRad: true,
      stashMemory: 0,
    }
  },
  getters: {
  },
  mutations: {
    addHistoy(state, history){
      const storedHistory = localStorage.getItem('historyList');
      if (storedHistory) {
        state.historyList = JSON.parse(storedHistory);
      }
      state.historyList.push(history);
      localStorage.setItem('historyList',JSON.stringify(state.historyList))
    },
    clearHistory(state){
      state.historyList = [];
      localStorage.setItem('historyList', []);
    },
    initializeHistory(state) {
      // 从localStorage中读取历史记录列表，并初始化到Vuex的state中
      const storedHistory = localStorage.getItem('historyList');
      if (storedHistory) {
        state.historyList = JSON.parse(storedHistory);
      }
    },

  },
  actions: {
  },
  modules: {
  }
})
