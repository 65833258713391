<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	export default {
		data() {
			return {
				isAndroidFlag:false,
				isIOSFlag:false,
				num: 30
			}
		},
		methods: {
			setNavBarHeight(height) {
				return this.num
			}
		},
		created(){
			// alert("navigator.userAgent",navigator.userAgent);
			if (/Android/i.test(navigator.userAgent)) {
			// 处理Android逻辑
				this.isAndroidFlag = true;
			} else if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
			// 处理iOS逻辑
				this.isIOSFlag = true;
			}
			
			// alert(`userAgent is ${navigator.userAgent}, this is a ${this.isAndroidFlag ? 'Android': 'IOS'} device, AndroidNativeAPI is ${window.AndroidNativeAPI}, NativeVibrate is ${window.AndroidNativeAPI && window.AndroidNativeAPI.NativeVibrate}`)
		},
		mounted() {
			// document.body.style.height  = (document.body.offsetHeight - this.num)  +'px'
			if(this.isAndroidFlag && window.AndroidNativeAPI) {
				this.num = window.AndroidNativeAPI.getNavBarHeight()
				window.setNavBarHeight = this.setNavBarHeight
				document.body.style.height  = (document.body.offsetHeight - this.num)  +'px'
			}
		}

  }
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
